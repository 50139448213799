import { ProjectProps } from "../../interfaces/projectProps";
import ProjectBlog from "../../components/ProjectBlog";


import NGD from "../../assets/pacbayes.png";

const PacBayes = ({ setShowBar, showBar }: ProjectProps) => {

    return (
        <ProjectBlog
            showBar={showBar}
            setShowBar={setShowBar}
            text={["This is an ongoing project in collaboration with Wu Lin and Daniel Roy of Vector AI Institute.\
                We are creating a flexible framework for implementing a suite of natural gradient optimizers for different uses,\
                including optimizing PAC-Bayes bounds and LLM fine-tuning."]}
            title={"PAC-Bayes and NGD"}
            github_link={"https://github.com/aldew5/PB-Optim"}
            website_link={""}
            image1={NGD}
            image2={""}
            width={"400px"}
        />
    )
}

export default PacBayes;
