import styles from "../styles/Template.module.css";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import Map from "../components/Map";
import Resume from "../assets/New_Resume.pdf";
import {
    Dispatch,
    SetStateAction,
} from "react";


interface AboutProps {
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>;
}
const About = ({ showBar, setShowBar }: AboutProps) => {

    return (
        <div>
            <div>
                <TopBar
                    showBar={showBar}
                    setShowBar={setShowBar}
                />
            </div>
            {(showBar) ?
                <div className={styles.bar}>
                    <SideBar
                        showBar={showBar}
                        setShowBar={setShowBar}
                    />
                </div>
                : <div></div>
            }
            <div className={(showBar) ? styles.main_bar : styles.main_normal}>
                <div className={styles.para2}>
                    <div style={{ marginBottom: "2rem", fontSize: "40px" }}>
                        About
                    </div>
                    <div style={{ marginBottom: "2rem", fontSize: "20px" }}>
                        <p>
                            I'm Alec Dewulf (/&aelig;l&#601;k d&#601;w&#650;lf/) from Sudbury, Ontario, and I'm a Junior at the University of Toronto studying computer science, mathematics,
                            and statistics. I like machine learning and theoretical computer science which I hope will provide
                            the grounds for a career in technology. I'm a research student at Vector Institute where I work on Bayesian learning and 
                            statistical learning theory with Daniel Roy and Wu Lin. I also study complexity and psuedorandomness with Roei Tell in the CS Theory Group at UofT. Previously,
                            I've worked on differential privacy at embARC Lab and visual attention at Wolfe Lab, Harvard Medical School. 
                            Here's my favourite quote:
                        </p>
                        <p>
                        “Oh, Jake," Brett said, "we could have had such a damned good time together.” "Yes," I said. "Isn't it pretty to think so?” -Ernest Hemingway
                        </p>
                    </div>
                    <div style={{ marginBottom: "2rem", fontSize: "20px" }}>
                        Click <a className={styles.link2} href="https://scholar.google.com/citations?user=TENblEUAAAAJ&hl=en">here</a> for my Google Scholar.
                        {/* and <a className={styles.link2} href={Resume}>here</a> for my CV */}
                    </div>
                </div>
                {/*
                <div className={styles.map}>
                    <Map />
                </div>
                */}
            </div>
            
        </div>
    )
}

export default About;